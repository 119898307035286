+(function($){
    function menuOverlay(){

        this.menuOverlayHtml = '<div class="akt-overlay-- akt-menu-overlay-- akt-fade-in" style="display: none;"></div>';   
        var self = this,
            navigation = '.menu-product-container',
            navigationLi = '#menu-product > li.has-mega-menu:first',
            $body = $('body');

        this.init = function(){
            this.showOverlayOnMenuHover();

            jQuery("#menu-product >  li.has-mega-menu:first").click(function(e){
                    // e.preventDefault();                  
                    jQuery(this).addClass("akt-nav-active");
                    jQuery('body').addClass("menu-opened");
                    jQuery('.akt-sticky-contact-form').addClass('akt-hidecontact-btn');
                    // if( jQuery('.akt-overlay').length < 0) {
                    //     $body.find('#page').prepend(self.menuOverlayHtml)
                    // }
                }
            );

            jQuery(document).on("click", function(e) {                
                if( jQuery(e.target).closest('#menu-product').length !== 1 ){
                    jQuery("#menu-product > li.has-mega-menu").removeClass("akt-nav-active");
                    jQuery('.akt-sticky-contact-form').removeClass('akt-hidecontact-btn');
                    jQuery('.akt-overlay').remove();
                    jQuery('body').removeClass("menu-opened");
                }                
            });

            jQuery(document).on( 'mouseenter', '#menu-product >  li',function(e){
                if( jQuery( window ).width() > 767 ) {                    
                    if( ! jQuery(e.target).hasClass('has-mega-menu"') ) {
                      jQuery("#menu-product > li.has-mega-menu").removeClass("akt-nav-active");
                    }
                }
            });

           

        }

        this.prependMenuOverlay = function () {

            $body
                .find('#page')
                .prepend(self.menuOverlayHtml)
            setTimeout(function () {
                $(document)
                    .find('.akt-menu-overlay.akt-fade-in')
                    .show();
            }, 200);

        };

        this.showOverlayOnMenuHover = function () {
           /* $(document).on({
                mouseleave: function () {
                    $('.akt-menu-overlay').remove(); 
                }
            }, navigation);
            */
            $(document).on({
                click: function () {
                    if (!$(this).hasClass('menu-item-has-children')) {
                        $('.akt-menu-overlay').remove();
                    }else{
                        $('.akt-menu-overlay').length <= 0 && self.prependMenuOverlay();  
                    }
                }
            }, navigationLi);
        };

    }

    var menuOverlayInstance = new menuOverlay();
    $(document).ready(function () {
        menuOverlayInstance.init();
    })

})(jQuery)